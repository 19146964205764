<template>
    <!--  -->
    <div class="expand-filter-wrapper content-wrapper">
        <!-- 信息中心-信息采集 -->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <ExpandFilterForm :formData="formData" marginBottom="0"
                        @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType" @changeSel="changeSel">
                    </ExpandFilterForm>
                    <div class="button-line"></div>
                    <div style="padding-bottom: 10px;">
                        <el-button type="primary"
                            @click="add()">创建表单</el-button>
                    </div>
                </div>
                <!-- 筛选 -->

                <!-- 表格 -->
                <table-data v-loading="loadingTable" :to-top="false" id="table" ref="table" :config="tableConfig"
                    :tableData="tableData">
                    <template v-slot:formTitle="slotData">
                        <div style="color: #3c7fff; cursor: pointer" @click="openDetails(slotData.data)">{{
                        slotData.data.formName }}</div>
                    </template>
                    <template v-slot:releaseStatus="slotData">
                       <StatusCard :status="slotData.data.releaseStatus"></StatusCard>
                    </template>
                    <template v-slot:operation="slotData">
                        <el-button type="text"
                            @click="releaseForm(slotData.data)" v-if="slotData.data.releaseStatus == '1'">发布</el-button>
                        <el-button type="text"
                            @click="statisticalForm(slotData.data)" v-if="slotData.data.releaseStatus == '2' || slotData.data.releaseStatus == '3'">统计</el-button>
                        <el-button type="text"
                            @click="editTableClick(slotData.data)" v-if="slotData.data.releaseStatus == '1'" >编辑</el-button>
                        <el-button type="text"
                            @click="finishForm(slotData.data)" v-if="slotData.data.releaseStatus == '2'">结束</el-button>
                        <el-button type="text"
                            @click="openDelayedDia(slotData.data)" v-if="slotData.data.releaseStatus == '2' || slotData.data.releaseStatus == '3'">延时</el-button>
                        <el-button type="text"
                            @click="copyForm(slotData.data)" v-if="slotData.data.releaseStatus == '1' || slotData.data.releaseStatus == '2'  || slotData.data.releaseStatus == '3'">复制</el-button>
                        <el-button type="text"
                            @click="deleteTableClick(slotData.data)" v-if="slotData.data.releaseStatus == '1'">删除</el-button>
                        <el-button type="text"
                            @click="openCode(slotData.data)" v-if="slotData.data.userPopulation == '3' && slotData.data.releaseStatus == '2'">二维码</el-button>
                    </template>
                </table-data>
                <!-- 分页器 -->
                <Pagination :page.sync="pageData.pageNum" :limit.sync="pageData.pageRow" :total="pageData.total"
                    @pagination="changeTableDataGet" />
                <!-- 延时弹窗 -->
                <dialog-wrapper :dialog-obj="delayedDialog" @handleClose="handleDelayedClose">
                    <div class="import-wrap">
                        <!-- <div>请设置新的表单填写的截止时间：</div> -->
                        <el-form ref="formRef" :model="delayedForm" :rules="rules" label-width="80px">
                            <el-form-item label="截止时间" prop="time">
                                <el-date-picker type="datetime" placeholder="选择时间" v-model="delayedForm.time"
                                    value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm"
                                    style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="text-align: right">
                        <el-button @click="handleDelayedClose">取消</el-button>
                        <el-button type="primary" @click="handleDelayedSubmit">确定</el-button>
                    </div>
                </dialog-wrapper>
                <!-- 遮罩 -->
                <dialog-wrapper :dialog-obj="codeDialog" @handleClose="handleCodeClose">
                    <div class="import-wrap" id="wxEwm">
                        <!-- <div>请设置新的表单填写的截止时间：</div> -->
                        <div style="text-align: center;">
                            <img :ref="`qrCodeUrl`"  :src="codeUrl" alt="" style="width: 200px;height: 200px;">
                        </div>
                    </div>
                </dialog-wrapper>
                <dialog-wrapper class="details-dia" :dialog-obj="detailsDialog" @handleClose="handleDetailsClose">
                    <div class="import-wrap">
                        <!-- <div>请设置新的表单填写的截止时间：</div> -->
                        <div style="text-align: center;">
                            <phone-details :showTip="showTip" :detailsObj="detailsObj" :subjectList='subjectList'></phone-details>
                        </div>
                        <div class="close-box">
                            <i class="el-icon-circle-close icon-color" @click="handleDetailsClose"></i>
                        </div>
                    </div>
                </dialog-wrapper>
            </div>
        </div>
    </div>
</template>

<script>
import { Loading, Error, Pagination, DialogWrapper } from "common-local"
import TableData from "@/components/scrollWrapper/Sub/TableData"
import { hasPermission } from "@/libs/utils";
import ExpandFilterForm from "./Sub/ExpandFilter"
import onResize from "@/mixins/onResize"
import RecordsList from '@/libs/pageListOptimal.js';
import PhoneDetails from './InterconnectionInfoCollection/phoneDetails.vue'
import StatusCard from "./InterconnectionInfoCollection/StatusCard.vue"
import { InterconnectionInfoCollection } from "@/models/InterconnectionInfoCollection.js";
// import QRCode from "qrcodejs2"
import QRCode from 'qrcodejs2-fix'
export default {
    name: 'ExpandFilter',
    mixins: [onResize],
    components: { Loading, Error, TableData, ExpandFilterForm, DialogWrapper, Pagination, PhoneDetails, StatusCard },
    data() {
        return {
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            subjectList: [{
                label: '语文',
                value: 1
            }, {
                label: '数学',
                value: 2
            }, {
                label: '英语',
                value: 3
            }],
            detailsObj: [],
            detailsDialog: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            delayedDialog: {
                title: "请设置新的表单填写的截止时间：",
                dialogVisible: false,
                width: "auto",
            },
            codeDialog: {
                title: "二维码",
                dialogVisible: false,
                width: "600px",
            },
            delayedForm: {
                time: ''
            },
            rules: {
                time: [
                    { required: true, message: '请选择截止时间', trigger: 'blur' },
                ],
            },
            showTip:false,
            formData: {
                data: [{
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "表单名称",
                    key: "formName"
                }, {
                    type: "select",
                    label: "",
                    value: "",
                    placeholder: "状态",
                    key: "releaseStatus",
                    list: [{
                        label: "未发布",
                        value: 1
                    }, {
                        label: "进行中",
                        value: 2
                    }, {
                        label: "已结束",
                        value: 3
                    }]
                }, {
                    type: "input",
                    label: "",
                    value: "",
                    placeholder: "创建人名称",
                    key: "createBy"
                }],
                btnList: [
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                    },
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                    },
                ],
                btnFormType: false
            },
            tableHeight: '',
            hasPermission,
            tableConfig: {
                thead: [
                    {
                        label: "表单名称",
                        minWidth: '210px',
                        type: "slot",
                        slotName: "formTitle",
                        prop: "formName",
                        tooltip: true,
                        // labelWidth:'350px',
                        align: 'center'
                    },
                    {
                        label: "采集范围",
                        minWidth: '180px',
                        // labelWidth:'200px',
                        prop: "missingCardRule",
                        type: "function",
                        callBack: (row) => {
                            switch (row.userPopulation) {
                                case "1":
                                    return '【教职工】'+ row.organInfo
                                case "2":
                                    return '【学生】'+ row.organInfo
                                default :
                                    return '【不限】'+ row.organInfo
                                }
                        }
                    },
                    {
                        label: "采集进度",
                        type: "function",
                        callBack: (row) => {
                            return (row.actualNum || '-') + '/' + (row.expectedNum || '-')
                        },
                        align: "center"
                    },
                    {
                        label: "发布时间",
                        // labelWidth: '280px',
                        type: "function",
                        callBack: (row) => {
                            return row.releaseTime ? row.releaseTime.slice(0, -3) : '-'
                        },
                        align: "center"
                    },
                    {
                        label: "截止时间",
                        // labelWidth: '180px',
                        prop: "deadline",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.deadline ? row.deadline.slice(0, 16) : ''
                        }
                    },
                    {
                        label: "创建人",
                        prop: "createByName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.createByName ? row.createByName : ''
                        }
                    },
                    {
                        label: "状态",
                        type: "slot",
                        slotName: "releaseStatus",
                        prop: "formName",
                        tooltip: true,
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: '180px',
                        slotName: "operation",
                        className: 'text-spacing-reduction',
                        renderHeader: null,
                    }
                ],
                height: "",
                check: false,
            },
            tableData: [],
            pageData: {
                pageNum: 1,
                pageRow: 20,
                total: 0
            },
            codeUrl: ''
        }
    },
    created() {
        //赋值--存储筛选项及滚动条高度
        let recordsList = new RecordsList()
        if (recordsList.get(this.$route.name)) {
            this.formData = recordsList.get(this.$route.name).formData;
        }
        this.tableDataGet()
    },
    methods: {
        /**
         * @Description: 筛选栏按钮操作
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 羡国柱
         * @Date: 2023-2-17 11:22:02
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary":
                    // 查询
                    this.tableDataGet()
                    // this.codeDialog.dialogVisible = true
                    // setTimeout(() => {
                    //     // let url = "https://study-h5.xinkaoyun.com/"
                    //     // let appid = "wx35d6582ae6a7cb70";
                    //     // https://cloudcampus.xinkaoyun.com:40005/dormitory-allocation/#/?token=school_39057dcbb3f54a95b663d33ea6330d77_0efc3e8b40d2443390a12d60a80d376b&schoolId=ab12433783574cc38ff27d11fa1d6aa8&isHouseMaster=0&classList=1





                    //     imgUrl = qrcodeCanvas.toDataURL("image/png")
                    //     this.codeUrl = imgUrl
                    //     console.debug(imgUrl);
                    // })
                    break
                case "reset":
                    // 查询重置
                    this.formData.data = this.$options.data.call().formData.data
                    this.tableDataGet(true)
                    break
            }
        },
        add() {
            // 新增跳转
            this.$emit("checkingsInIdChange", "")
            this.$emit('expandFilterUpdateManagement')
            this.$emit('changeComp',{
                type: 'add',
                component: 'CreatForm',
                data:""
            })
        },
        /**
         * @Description: 树形菜单选择回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 羡国柱
         * @Date: 2023-2-17 11:22:06
         */
        changeSel(item) {

        },
        /**
         * @Description: 分页事件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-09-18 11:18:30
         */
        changeTableDataGet() {
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.tableDataGet(true)
        },
        //获取列表数据
        tableDataGet(initPage) {
            this.loadingTable = true
            let data = {}
            if (!initPage) {
                this.pageData = this.$options.data.call().pageData
            }
            //赋值--存储筛选项及滚动条高度
            let recordsList = new RecordsList()
            if (recordsList.get(this.$route.name)) {
                this.formData.data.forEach(item => {
                    data[item.key] = recordsList.get(this.$route.name).name;
                })
            }
            this.formData.data.forEach(item => {
                data[item.key] = item.value
            })
            const InterconnectionInfoCollectionModel = new InterconnectionInfoCollection()
            InterconnectionInfoCollectionModel.getSchoolCollectFormList({
                ...data,
                schoolId: this.$store.state.schoolId,
                pageNum: this.pageData.pageNum,
                pageRow: this.pageData.pageRow
            }).then((res)=>{
                if (res.data.code === "200") {
                    console.debug("[tableDataGet]res.data.data.list1111111111111111111", JSON.parse(JSON.stringify(res)))
                    this.tableData = res.data.data.list
                    this.pageData.total = res.data.data.totalCount
                    //赋值--存储筛选项及滚动条高度
                    // this.$nextTick(() => {
                    //     let recordsList = new RecordsList()
                    //     if (recordsList.get(this.$route.name)) {
                    //         this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                    //     }
                    //     //移除--存储筛选项及滚动条高度
                    //     recordsList.remove(this.$route.name)
                    //     this.loadingTable = false;
                    // })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                this.loadingTable = false
            })
            // this._fet("/school/schoolCollectForm/list", {
            //     ...data,
            //     schoolId: this.$store.state.schoolId,
            //     pageNum: this.pageData.pageNum,
            //     pageRow: this.pageData.pageRow
            // }).then(res => {

            // })
        },
        /**
         * @Description: 表单详情
         * @Author: 王梦瑶
         * @Date: 2024-08-07 14:55:16
         */
        openDetails(row) {
            const InterconnectionInfoCollectionModel = new InterconnectionInfoCollection()
            InterconnectionInfoCollectionModel.getSchoolCollectFormInfo(row.id).then((res)=>{
                if(res.data.code == 200){
                    let obj = res.data.data
                    this.detailsObj = JSON.parse(JSON.parse(obj.formInfo) )
                    let index = this.detailsObj.findIndex(item => item.compType == 'EvaluateTeachers' || item.compType == 'EvaluateTeachersBan')
                    if(index != -1){
                        this.showTip = true
                    }else{
                        this.showTip = false
                    }
                    this.detailsDialog.dialogVisible = true
                }else{

                }
            })
        },
        /**
         * @Description: 复制表单
         * @Author: 王梦瑶
         * @Date: 2024-08-06 14:53:54
         */
        copyForm(data) {
            this.$emit('changeComp',{
                type: 'copy',
                component: 'CreatForm',
                data:{
                    id: data.id
                }
            })
        },
        /**
         * @Description: 发布表单
         * @Author: 王梦瑶
         * @Date: 2024-08-06 14:56:26
         */
        releaseForm(data) {
            this.$confirm("确定要现在发布表单吗？发布后填写人可以看到表单并填写。", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                const InterconnectionInfoCollectionModel = new InterconnectionInfoCollection()
                InterconnectionInfoCollectionModel.releaseSchoolCollectForm({
                    id: data.id,
                    releaseStatus: '2'
                }).then((res)=>{
                    if (res.data.code === "200") {
                		this.$message.success("发布成功");
                	} else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                	}
                    this.tableDataGet();
                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 打开延时弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-06 14:57:20
        */
        openDelayedDia(data) {

            this.selectForm = data
            this.delayedDialog.dialogVisible = true

        },
        /**
         * @Description: 关闭延时弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-06 17:50:00
         */
        handleDelayedClose() {
            this.selectForm = {}
            this.$refs.formRef.resetFields();
            this.delayedDialog.dialogVisible = false
        },
        /**
         * @Description: 确认延时
         * @Author: 王梦瑶
         * @Date: 2024-08-06 17:50:14
         */
        handleDelayedSubmit() {
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    const InterconnectionInfoCollectionModel = new InterconnectionInfoCollection()
                    InterconnectionInfoCollectionModel.delayedSchoolCollectForm({
                        id: this.selectForm.id,
                        releaseStatus: '2',
                        deadline: this.delayedForm.time +':00',
                        xxlEnd: this.selectForm.xxlEnd
                    }).then((res)=>{
                        if (res.data.code === "200") {
                            this.$message.success("延时成功");
                            this.$refs.formRef.resetFields();
                            this.delayedDialog.dialogVisible = false
                            this.tableDataGet();
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg);
                        }
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        /**
         * @Description: 打开二维码
         * @Author: 王梦瑶
         * @Date: 2024-08-06 17:42:23
         */
        openCode(data) {
            console.debug(JSON.parse(JSON.stringify(data)));
            this.codeDialog.dialogVisible = true
            let {id} = data

        //    let param = `${id}`
           let param = `${id}|${this.$store.state.schoolId}`

            setTimeout(() => {

                // 正式
                let url = "https://cloudcampus-h5.xinkaoyun.com/h5-all/information-collection/dist/#/"
                let appid = "wx93d08a0b039357be";
                console.log("[App Launch]redirect_uri:", url)
                let authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_userinfo&state=${param}#wechat_redirect`
                let imgUrl = "";
                let eachQrCode = "qrCodeUrl"
                this.$refs[eachQrCode].innerHTML = ""

                console.debug(authURL);

                imgUrl = new QRCode(this.$refs[eachQrCode], {
                    text: authURL, // 需要转换为二维码的内容
                    width: 1000,
                    height: 1000,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.Q,
                })
                let qrcodeCanvas = this.$refs[eachQrCode].getElementsByTagName("canvas")[0]
                imgUrl = qrcodeCanvas.toDataURL("image/png")
                this.codeUrl = imgUrl
            })


        },
        /**
         * @Description: 关闭二维码弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-06 17:43:43
        */
        handleCodeClose() {
            this.codeDialog.dialogVisible = false
        },
        /**
         * @Description: 关闭表单详情
         * @Author: 王梦瑶
         * @Date: 2024-08-07 14:57:04
         */
        handleDetailsClose() {
            this.detailsDialog.dialogVisible = false
        },
        /**
         * @Description: 结束表单
         * @Author: 王梦瑶
         * @Date: 2024-08-06 15:08:31
         */
        finishForm(data) {
            this.$confirm("确定要现在结束该表单的信息收集吗？结束后，表单填写人将无法再次填写表单内容！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                const InterconnectionInfoCollectionModel = new InterconnectionInfoCollection()
                InterconnectionInfoCollectionModel.finishSchoolCollectForm({
                    id: data.id,
                    releaseStatus: '3'
                }).then((res)=>{
                    if (res.data.code === "200") {
                		this.$message.success("结束成功");
                		this.tableDataGet();
                	} else if (res.data && res.data.msg) {
                		this.$message.error(res.data.msg);
                	}
                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 统计表单
         * @Author: 王梦瑶
         * @Date: 2024-08-06 14:58:58
         */
        statisticalForm(data) {
            console.debug(JSON.parse(JSON.stringify(data)),"sdfsfdsf");
            //存储筛选项及滚动条高度
            let recordsList = new RecordsList()
            let ruleNameValue = ''
            this.formData.data.forEach(item => {
                ruleNameValue = item.value
            })
            recordsList.set(this.$route.name, {
                formData: this.formData,
                name: ruleNameValue,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$emit('changeComp',{
                type: 'statostics',
                component: 'InterconnectionInfoCollectionStatostics',
                data: {
                    id: data.id,
                    formType: data.formType,
                    formName: data.formName,
                    fillStatus: data.fillStatus,
                    releaseStatus: data.releaseStatus,
                }
            })
        },
        /**
         * @Description: 编辑表单
         * @Author: 王梦瑶
         * @Date: 2024-08-06 14:55:10
         */
        editTableClick(data) {
            //存储筛选项及滚动条高度
            let recordsList = new RecordsList()
            let ruleNameValue = ''
            this.formData.data.forEach(item => {
                ruleNameValue = item.value
            })
            recordsList.set(this.$route.name, {
                formData: this.formData,
                name: ruleNameValue,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            console.log(recordsList.get(this.$route.name), '编辑时存储的内容recordsList.get(this.$route.name)');
            this.$emit('changeComp',{
                type: 'edit',
                component: 'CreatForm',
                data: {
                    id: data.id,
                    formType: data.formType
                }
            })
            this.$emit("checkingsInIdChange", data.id)
            this.$emit('expandFilterUpdateManagement')
        },
        /**
         * @Description: 删除表单
         * @Author: 王梦瑶
         * @Date: 2024-08-06 14:53:54
         */
        deleteTableClick(data) {
            this.$confirm("确定要删除吗？删除后该表单的所有信息将不再保留且不可恢复！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                const InterconnectionInfoCollectionModel = new InterconnectionInfoCollection()
                InterconnectionInfoCollectionModel.deletedSchoolCollectForm({
                    id: data.id,
                }).then((res)=>{
                    if (res.data.code === "200") {
                		this.$message.success("删除成功");
                	} else if (res.data && res.data.msg) {
                		this.$message.error(res.data.msg);
                	}
                    this.tableDataGet();
                })
            }).catch(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content-wrapper{
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    // justify-content: space-between;
    text-align: center;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.expand-filter-wrapper {
    ::v-deep .expand-filter {

        .el-form--inline .el-form-item__label,
        .btn-form-type {
            display: none;
        }
    }
}

.close-box {
    text-align: center;
    font-size: 40px;
}

.icon-color {
    color: #fff;
    cursor: pointer;
}
</style>
<style lang="scss">
.details-dia {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog .el-dialog__body {
        margin-top: 0px;
        padding: 0px;
    }

    .el-dialog__header {
        display: none !important;
    }
}

</style>
